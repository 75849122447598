<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <WindowTitleBar title="Book Creation" @loadData="loadData" @close_window="closeThis">{{ setTitle() }}      </WindowTitleBar>

      <div class="card-body" >

        <form style="padding: 0.5em;">

          <!--ISBN-->
          <div class="form-group row" >
            <label for="txtISBN" class="col-md-1 col-form-label font-weight-semibold">ISBN</label>
            <div class="col-md-5">
              <input  ref="txtISBN" type="text" class="form-control" id="txtISBN" placeholder="Book ISBN" maxlength="20" style="font-size: 18px;" autocomplete="off" autofocus v-if="book" v-model="book.isbn">
            </div>

            <!--            <label for="txtCode" class="col-md-1 col-form-label font-weight-semibold">Accession No</label>-->
            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend position-static">
                  <label class="form-control dropdown-toggle font-weight-semibold" data-toggle="dropdown" aria-expanded="true">Accession No</label>
                  <div class="dropdown-menu" style="">
                    <a href="#" class="dropdown-item" @click="get_current_access_no">Auto Generate</a>
                    <a href="#" class="dropdown-item">Manual</a>
                  </div>
                </div>
                <input type="text" class="form-control" placeholder="Accession No" id="txtCode" maxlength="20" v-if="book" v-model="book.code">
              </div>
              <!--              <input type="text" class="form-control" id="txtCode" maxlength="20" autocomplete="off" v-if="book" v-model="book.code">-->
            </div>

            <label for="txtQty" class="col-md-1 col-form-label font-weight-semibold">Copies</label>
            <div class="col-md-2">
              <input type="number" class="form-control text-center" id="txtQty" autocomplete="off" v-if="book" v-model="book.qty">
            </div>
          </div>

          <!--Book Name & Titile-->
          <div class="form-group row" >
            <label for="txtname" class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Title</label>
            <div class="col-md-5 col-sm-10">
              <input type="text" class="form-control" id="txtname" maxlength="100" autocomplete="off" v-if="book" v-model="book.name">
            </div>

            <label for="txt_Title" class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Sub Title</label>
            <div class="col-md-5 col-sm-10">
              <input type="text" class="form-control" id="txt_Title" maxlength="100" autocomplete="off" v-if="book" v-model="book.title">
            </div>

          </div>

          <!--Category-->
          <div class="form-group row" >
            <label for="txtPublisher" class="col-md-1 col-form-label font-weight-semibold">Publisher</label>
            <div class="col-md-5">
              <select type="text" class="form-control" id="txtPublisher" v-if="book" v-model="book.publisher_id">
                <option v-for="pub in publishers" v-bind:value="pub.id">
                  {{ pub.name }}
                </option>
              </select>
            </div>

            <label for="txtEdition" class="col-md-1 col-form-label font-weight-semibold">Edition</label>
            <div class="col-md-2">
              <input type="text" class="form-control" id="txtEdition" maxlength="100" autocomplete="off"  v-if="book" v-model="book.edition">
            </div>

            <label for="txtEditionYear" class="col-md-1 col-form-label font-weight-semibold">Year</label>
            <div class="col-md-2">
              <input type="number" class="form-control  text-center" id="txtEditionYear" max="2050" v-if="book" v-model="book.edition_year">
            </div>

          </div>

          <!--Publisher-->
          <div class="form-group row" >

            <label for="txtCategory" class="col-md-1 col-form-label font-weight-semibold">Category</label>
            <div class="col-md-5">
              <select class="form-control" id="txtCategory" v-if="book" v-model="book.category_id">
                <option v-for="pub in categories" v-bind:value="pub.id">
                  {{ pub.name }}
                </option>
              </select>
            </div>

            <label for="txtLanguage" class="col-md-1 col-form-label font-weight-semibold">Language</label>
            <div class="col-md-2">
              <select class="form-control" id="txtLanguage" v-if="book" v-model="book.language">
                <option value="en" selected>English</option>
                <option value="ta">Tamil</option>
                <option value="ma">Malayam</option>
                <option value="hi">Hindi</option>
              </select>
            </div>

            <label for="txtPublishYear" class="col-md-1 col-form-label font-weight-semibold">Year</label>
            <div class="col-md-2">
              <input type="number" class="form-control  text-center" id="txtPublishYear" max="2050" v-if="book" v-model="book.publisher_year">
            </div>

          </div>

          <!--Author-->
          <div class="form-group row" >
            <label class="col-md-1 col-form-label font-weight-semibold">Authors</label>
            <div class="col-md-8 ">
              <select id="cmbAuthors" class="form-control select-access-multiple-value" multiple="multiple" data-fouc  >
                <option v-for="pub in authors" v-bind:value="pub.id">
                  {{ pub.name }}
                </option>
              </select>
            </div>

            <div class="col-md-3">
              <input class="form-control" id="txtBookImage" type="file" accept="image/*" @change="choose_file" placeholder="Please Select Cover Image">
            </div>

          </div>

          <!--Price, Pages Location-->
          <div class="form-group row" >

            <label for="txtLocation" class="col-md-1 col-form-label font-weight-semibold">Location</label>
            <div class="col-md-3">
              <input id="txtLocation" type="text" class="form-control" autocomplete="off" placeholder="Book Rack Location" v-if="book" v-model="book.rack">
            </div>

            <label for="txtcallno" class="col-md-1 col-form-label font-weight-semibold">Call No</label>
            <div class="col-md-1">
              <input id="txtcallno" type="text" class="form-control" autocomplete="off" placeholder="Call No" v-if="book" v-model="book.call_no">
            </div>

            <label for="txtPrice" class="col-md-1 col-form-label font-weight-semibold">Price</label>
            <div class="col-md-2">
              <input type="number" class="form-control  text-right" id="txtPrice" min="0" v-if="book" v-model="book.price">
            </div>

            <label for="txtPages" class="col-md-1 col-form-label font-weight-semibold">Pages</label>
            <div class="col-md-2">
              <input type="number" class="form-control text-right" id="txtPages" min="0" v-if="book" v-model="book.pages">
            </div>

          </div>

          <!--Price, Pages Location-->
          <div class="form-group row" >
            <label for="txtLocation" class="col-md-1 col-form-label font-weight-semibold">Department</label>
            <div class="col-md-5">
              <select class="form-control" v-if="book" v-model="book.department_id">
                <option v-for="pub in departments" v-bind:value="pub.id">
                  {{ pub.name }}
                </option>
              </select>
            </div>

            <label for="txtPrice" class="col-md-1 col-form-label font-weight-semibold">Currency</label>
            <div class="col-md-2">
              <select class="form-control" v-if="book" v-model="book.currency">
                <option value="INR" selected>Indian Rupee ₹</option>
                <option value="USD" >United States Dollar $</option>
                <option value="GBP" >United Kingdom Pound £</option>
                <option value="SGD" >Singapore Dollar $</option>
                <option value="CAD" >Canada Dollar $</option>
                <option value="AUD" >Australia Dollar $</option>
              </select>
            </div>

            <label for="txtPages" class="col-md-1 col-form-label font-weight-semibold">Conv.Rate</label>
            <div class="col-md-2">
              <input type="number" class="form-control text-right" min="1" v-if="book" v-model="book.conv_rate">
            </div>

          </div>

          <div class="form-group row" >
            <label for="txtPages" class="col-md-1 col-form-label font-weight-semibold">Acquisition Date</label>
            <div class="col-md-2">
              <input type="date" class="form-control text-center" v-model="book.acquisition_date" >
            </div>

            <label for="txtPages" class="col-md-1 col-form-label font-weight-semibold">Bill No</label>
            <div class="col-md-2">
              <input type="text" class="form-control text-center" maxlength="10" v-model="book.bill_no" >
            </div>

            <label for="txtPages" class="col-md-1 col-form-label font-weight-semibold">Bill Date</label>
            <div class="col-md-2">
              <input type="date" class="form-control text-center" v-model="book.bill_date" >
            </div>

            <div class="col-md-3 text-right">
              <SaveButton title="Add" @handle_save_action="perform_save_action"/>
            </div>

          </div>

        </form>


      </div>

    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'
import WindowTitleBar from '../../../components/core/WindowTitleBar'
import Cleave from 'cleave.js'

export default {
  name: 'BookForm',
  components: {
    WindowTitleBar,
    SaveButton
  },
  data () {
    return {
      current_access_no: 0,
      book: JSON.parse('{"id":0,"status":"Available","type":"Book","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":0,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"subscribe_no":"","city":""}'),
      publishers:[],
      authors:[],
      categories: [],
      departments: [],
    }
  },
  store,
  component: {

  },
  props: {
    mybook: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Available","type":"Book","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":0,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"subscribe_no":"","city":""}')
    }
  },
  beforeMount () {
    this.book = this.mybook;
    // $('#cmbAuthors').select2('val', [1002,1003]);
  },
  created () {
    this.$data.book = JSON.parse('{"id":0,"status":"Available","type":"Book","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":0,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"subscribe_no":"","city":""}')
    this.$data.book.year = (new Date()).getFullYear();
  },
  mounted () {
    const self = this;

    this.$data.book.year = (new Date()).getFullYear();
    this.loadCategories();
    this.loadAuthors();
    this.loadPublishers();
    this.loadDepartments();

    $('#cmbAuthors').select2({
      placeholder: "Select Author...",
      minimumResultsForSearch: Infinity
    });

    //txtISBN
    new Cleave(self.$refs.txtISBN, {
      delimiter: ' ',
      blocks: [3, 1, 2, 6, 1],
    });


    $('#txtISBN').focus();

  },
  methods: {
    closeThis () {
      this.$emit('book_window_closed');
      if(this.book.id == 0 ) {
        this.$router.push("/")
      }
    },
    setTitle () {
      return this.book.id == 0 ? 'Book Creation' : 'Book Updation'
    },
    loadData(){
      try {
        this.loadCategories();
        this.loadAuthors();
        this.loadPublishers();
      }catch (e) {
        alert(e);
      }
    },
    choose_file(evt){
      const self = this;
      const file = evt.target.files[0];
      const reader = new FileReader();
      reader.onloadend = function() {
        const base64 = reader.result;
        self.book.image = base64;
      };
      reader.readAsDataURL(file);
    },
    loadPublishers(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.publishers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/publishers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.publishers = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadAuthors(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.authors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/authors`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.authors = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    get_current_access_no(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });
      self.$data.current_access_no = "";
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/books/current_accessno`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.current_access_no = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadDepartments(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.departments = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.departments = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadCategories(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.categories = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/categories`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.categories = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    perform_save_action(){
      const self = this;
      try {

        self.$data.book.authors = [];
        $("#cmbAuthors :selected").each(function () {
          let author = {"id": parseInt(this.value), "name": "" };
          self.$data.book.authors.push(author);
        });

        if( self.$data.book.title == null) {
          self.$data.book.title = "";
        }

        if( self.$data.book.code == null) {
          self.$data.book.code = "";
        }

        if( self.$data.book.edition == null) {
          self.$data.book.edition = "";
        }

        if( self.$data.book.isbn == null) {
          self.$data.book.isbn = "";
        }

        if( self.$data.book.rack == null) {
          self.$data.book.rack = "";
        }

        if(self.$data.book.name.trim().length == 0) {
          alert('Invalid Book Name');
          return;
        }else if( parseInt(self.$data.book.qty) < 1 ) {
          alert('Invalid Book Quantity');
          return;
        }else if( parseInt(self.$data.book.category_id) < 1 ) {
          alert('Invalid Book Category');
          return;
        }else if( parseInt(self.$data.book.publisher_id) < 1 ) {
          alert('Invalid Book Publisher');
          return;
        }else if( parseInt(self.$data.book.publisher_id) < 1 ) {
          alert('Invalid Book Publisher');
          return;
        }else if( $("#cmbAuthors").val().toString().length == 0 ) {
          alert('Invalid Authors');
          return;
        }

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        const requestOptions = {
          method: (self.$data.book.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.book)
        }

        console.log(JSON.stringify(self.$data.book));

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/book`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtISBN').focus()}, timer:1500 });
            self.book = JSON.parse('{"id":0,"status":"Available","type":"Book","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":0,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"subscribe_no":"","city":""}');
            self.$emit('book_saved',resp.data);
            $('#txtISBN').focus();
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => { $('#txtISBN').focus()}, timer:3000 });
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtISBN').focus()}, timer:3000 });
        }).finally(()=>{
          $('#mycard').unblock();
        });

      }catch (e) {
        alert(e);
      }

    },
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00!important;
  color: #0a0a0a!important;
}
</style>
